/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card>
    <b-form
      v-if="vendor"
      @submit.prevent="submitForm"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Name"
            label-for="h-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-name"
              v-model="vendor.name"
              :disabled="true"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="h-email"
            label-cols-md="4"
          >
            <b-form-input
              id="h-email"
              v-model="vendor.email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Subject"
            label-for="h-subject"
            label-cols-md="4"
          >
            <b-form-input
              id="h-subject"
              v-model="vendor.subject"
              placeholder="Subject"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'EditVendor',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
  },
  props: [
    'vendorId',
  ],
  data() {
    return {
      vendor: '',
      loading: false,
    }
  },
  beforeMount() {
    this.loadVendor()
  },
  methods: {
    async loadVendor() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchVendor', self.vendorId)
        .then(() => {
          self.vendor = self.$store.state.app.vendor
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    submitForm() {
      const self = this

      this.$store.dispatch('app/saveVendor', this.vendor).catch().then(() => {
        self.$router.push('/vendors')
      })
    }
  },
}
</script>
